import axios from 'axios';
import i18n from '../../../i18n/index'

let util = {};
util.ajax = axios.create({
  timeout: 30000,
  headers: { Authorization: 'Sys ' + sessionStorage.getItem('token') }
});

util.inOf = function(arr, targetArr) {
  let res = true;
  arr.map(item => {
    if (targetArr.indexOf(item) < 0) {
      res = false;
    }
  });
  return res;
};

util.oneOf = function(ele, targetArr) {
  if (targetArr.indexOf(ele) >= 0) {
    return true;
  } else {
    return false;
  }
};

util.showThisRoute = function(itAccess, currentAccess) {
  if (typeof itAccess === 'object' && itAccess.isArray()) {
    return util.oneOf(currentAccess, itAccess);
  } else {
    return itAccess === currentAccess;
  }
};

util.getRouterObjByName = function(routers, name) {
  let routerObj = {};
  routers.forEach(item => {
    if (item.name === 'otherRouter') {
      item.children.forEach((child, i) => {
        if (child.name === name) {
          routerObj = item.children[i];
        }
      });
    } else {
      if (item.children) {
        if (item.children.length === 1) {
          if (item.children[0].name === name) {
            routerObj = item.children[0];
          }
        } else {
          item.children.forEach((child, i) => {
            if (child.name === name) {
              routerObj = item.children[i];
            }
          });
        }
      }
    }
  });
  return routerObj;
};

util.handleTitle = function(vm, item) {
  return item.title;
};

util.setCurrentPath = function(vm, name) {
  let title = '';
  let isOtherRouter = false;
  vm.$store.state.app.routers.forEach(item => {
    if (item.children && item.children.length === 1) {
      if (item.children[0].name === name) {
        title = util.handleTitle(vm, item);
        if (item.name === 'otherRouter') {
          isOtherRouter = true;
        }
      }
    } else {
      if (item.children) {
        item.children.forEach(child => {
          if (child.name === name) {
            title = util.handleTitle(vm, child);
            if (item.name === 'otherRouter') {
              isOtherRouter = true;
            }
          }
        });
      }
    }
  });
  let currentPathArr = [];
  if (name === 'home_index') {
    currentPathArr = [
      {
        title: util.handleTitle(vm, util.getRouterObjByName(vm.$store.state.app.routers, 'home_index')),
        path: '/home',
        name: 'home_index'
      }
    ];
    // } else if ((name.indexOf('_index') >= 0 || isOtherRouter) && name !== 'home_index') {
  } else if (!isOtherRouter && name !== 'home_index') {
    currentPathArr = [
      {
        title: util.handleTitle(vm, util.getRouterObjByName(vm.$store.state.app.routers, 'home_index')),
        path: '/home',
        name: 'home_index'
      },
      {
        title: title,
        path: '',
        name: ''
      }
    ];
  } else if (isOtherRouter && name !== 'home_index') {
    let currentPathObj = vm.$store.state.app.routers.find(item => item.name == 'otherRouter');
    if (currentPathObj.children.length <= 1) {
      currentPathArr = [
        {
          title: '首页',
          path: '/home',
          name: 'home_index'
        }
      ];
    } else if (currentPathObj.children.length <= 1 && currentPathObj.name !== 'home_index') {
      currentPathArr = [
        {
          title: '首页',
          path: '/home',
          name: 'home_index'
        },
        {
          title: currentPathObj.children[0].title,
          path: '',
          name: ''
        }
      ];
    } else {
      currentPathArr = [
        {
          title: '首页',
          path: '/home',
          name: 'home_index'
        },
        {
          title: util.handleTitle(vm, util.getRouterObjByName(vm.$store.state.app.routers, name)),
          path: '',
          name: ''
        }
      ];
    }
  }

  vm.$store.commit('setCurrentPath', currentPathArr);

  return currentPathArr;
};

util.openNewPage = function(vm, name, argu, query) {
  if (vm.$store === undefined) {
    return;
  }
  let pageOpenedList = vm.$store.state.app.pageOpenedList;

  let openedPageLen = pageOpenedList.length;
  let i = 0;
  let tagHasOpened = false;

  // 主菜单数据
  // let MainMenu = JSON.parse(localStorage.getItem('permissionList')).map(item => item.resUrl)
  while (i < openedPageLen) {
    if (name === pageOpenedList[i].name || name == 'sys/externalLinks/ExternalLinksView') {
      //
      vm.$store.commit('pageOpenedList', {
        index: i,
        argu: argu,
        query: query
      });
      tagHasOpened = true;
      break;
    }
    i++;
  }

  if (!tagHasOpened) {
    let tag = vm.$store.state.app.tagsList.find(item => {
      if (item.children && item.children.length > 0) {
        return item.children.find(o => o.name === name);
      } else {
        return name === item.name;
      }
    });

    if (tag) {
      tag = tag.children && tag.children.length > 0 ? tag.children.find(o => o.name === name) : tag;
      if (argu) {
        tag.argu = argu;
      }
      if (query) {
        tag.query = query;
      }
      vm.$store.commit('increateTag', tag);
    }
  }
  vm.$store.commit('setCurrentPageName', name);
};

util.toDefaultPage = function(routers, name, route, next, ssoName) {
  let len = routers.length;
  let i = 0;
  let notHandle = true;
  while (i < len) {
    if (routers[i].name === name && routers[i].children && routers[i].redirect === undefined) {
      route.replace({
        name: ssoName || routers[i].children[0].name
      });
      notHandle = false;
      next();
      break;
    }
    i++;
  }
  if (notHandle) {
    next();
  }
};

util.fullscreenEvent = function(vm) {
  // 权限菜单过滤相关
  vm.$store.commit('updateMenulist');
};
util.initRouter = function(vm, callBack) {
  const constRoutes = [];
  const otherRoutes = [];

  // 404路由需要和动态路由一起注入
  const otherRouter = [
    {
      path: '/*',
      name: 'error-404',
      meta: {
        title: '404-页面不存在'
      },
      component: 'error-page/404'
    }
  ];
  // 模拟异步请求
  let token = sessionStorage.getItem('token');
  let lang = localStorage.lang || 'zh_CN';
  // if(uid) {
  let menuUri = vm.menuUri ? vm.menuUri : token ? vm.prefix + '/microarch/sys/sysuser/getMenuTreeTl?lang=' + lang : '';
  if (menuUri == '') {
    return false;
  }
  vm.$myHttp({
    method: 'get',
    url: menuUri,
    headers: {
      Authorization: 'Sys ' + token
    }
  }).then(res => {
    let menuData;
    if (vm.menuUri) {
      menuData = res.data;
    } else {
      if (res.data && res.data.ext) {
        menuData = JSON.parse(res.data.ext);
        // todo 三级菜单前端测试数据

        console.log(menuData);
        // var list = [
        //     {
        //         component: "sys/workFlow/toDoList",
        //         icon: "el-icon-edit-outline",
        //         isClickRefresh: "1",
        //         isExternalLink: "0",
        //         isShow: "1",
        //         memo: "待办",
        //         name: "sys/workFlow/toDoList",
        //         path: "sys/workFlow/toDoList",
        //         title: "我的待办"
        //     }
        // ]
        // menuData[0].children[0].children = list
        // menuData[0].children[0].name = 'submenu'
        // menuData[0].children[0].path = 'submenu'
        // menuData[0].children[0].component = 'sys/subMenu'

        // menuData[0].children[1].children = [
        //     {
        //         component: "sys/workFlow/haveList",
        //         icon: "el-icon-tickets",
        //         isClickRefresh: "0",
        //         isExternalLink: "0",
        //         isShow: "1",
        //         memo: "",
        //         name: "sys/workFlow/haveList",
        //         path: "sys/workFlow/haveList",
        //         title: "我的已办11"
        //     }
        // ]
        // menuData[0].children[1].name = 'submenu1'
        // menuData[0].children[1].path = 'submenu1'
        // menuData[0].children[1].component = 'sys/subMenu'

        for (var i = 0; i < menuData.length; i++) {
          if (menuData[i].children && menuData[i].children.length == 1) {
            // menuData[i].title = menuData[i].children[0].title;
            // 是否刷新
            menuData[i].isExternalLink = menuData[i].children[0].isExternalLink;
            menuData[i].memo = menuData[i].children[0].memo;
          }
        }
      }
    }

    util.initRouterNode(constRoutes, menuData);
    util.initRouterNode(otherRoutes, otherRouter);
    // 添加主界面路由
    vm.$store.commit('updateAppRouter', constRoutes);
    // 添加全局路由
    vm.$store.commit('updateDefaultRouter', otherRoutes);
    // 刷新界面菜单
    vm.$store.commit('updateMenulist', constRoutes);

    let tagsList = [];
    vm.$store.state.app.routers.map(item => {
      if (item.children && item.children.length > 0) {
        tagsList.push(...item.children);
      } else {
        tagsList.push();
      }
    });

    vm.$store.commit('setTagsList', tagsList);
    if (callBack) callBack();
  });
  // }
};

/**
 * 检测数据是否重复       （使用在表单校验中）
 * @param url            校验请求的地址
 * @param entityData     校验数据
 * @param me             当前上下文（this）
 * @param message        提示信息
 * @param checkCode      筛选code
 * @param callback       校验规则回调方法
 */
util.checkData = function(checkCode, entityData, me, message, callback, url) {
  if (!util.isEmpty(entityData)) {
    axios({
      method: 'POST',
      url: url || me.prefix + 'sysConfig/getSysDictionaryList' + window.suffix,
      data: {
        entity: entityData
      }
    }).then(res => {
      // 成功回调方法
      if (res.data.total > 0) {
        callback(new Error(message));
      } else {
        callback();
      }
    });
  }
};

/**
 * 判断数据的某项属性是否为空
 * @param data
 * @returns {boolean}
 */
util.isEmpty = data => {
  for (let items in data) {
    if (data[items] == '') {
      return true;
    }
  }
  return false;
};

/**
 *
 * @param rule
 * @param value
 * @param callback
 * @param errMsg
 */
util.validatePass = (rule, value, callback, errMsg) => {
  if (value === '') {
    callback(new Error(errMsg));
  } else {
    callback();
  }
};

util.addPreZero = num => {
  var t = (num + '').length,
    s = '';

  for (var i = 0; i < 4 - t; i++) {
    s += '0';
  }

  return s + num;
};
util.removeSymbol = value => {
  if (value !== '' && value !== undefined) {
    var formatValue = value.toString();
    var ilen = 0;
    var alen = 0;
    var valueString;
    var checkArray = [formatValue];
    var returnNewValue = '';
    for (alen = 0; alen < checkArray.length; alen++) {
      valueString = checkArray[alen];
      for (ilen = 0; ilen < valueString.length; ilen++) {
        if (valueString.charAt(ilen) === ',') {
          if (alen === 0) {
            returnNewValue = formatValue.replace(/,/g, '');
            break;
          }
        }
      }
    }
    if (returnNewValue === '') {
      returnNewValue = value;
    }
    return returnNewValue;
  }
};
util.formatMoney = value => {
  if (value !== '' && value !== undefined) {
    var ilen = 0;
    var beforeReplace;
    var singleFlag = false;
    var valueString = value.toString();
    for (ilen = 0; ilen < valueString.length; ilen++) {
      if (valueString.charAt(ilen) === ',') {
        singleFlag = true;
        break;
      }
    }
    if (singleFlag === true) {
      beforeReplace = value.replace(/,/g, '');
    } else {
      beforeReplace = parseFloat(value);
    }
    beforeReplace.toString().split(',');
    if (value !== undefined && value !== '') {
      var valueArray = beforeReplace.toString().split('.');
      var intPartFormat = beforeReplace.toString();
      var nowValue;
      var pointAfter = '';
      var pointBefore = valueArray[0].toString();
      // 先判断输入的小数点后是几位 如果大于两位则需要四舍五入
      if (valueArray[1]) {
        if (valueArray[1].length < 2 || valueArray[1].length === 2) {
          pointAfter = valueArray[1];
        } else if (valueArray[1].length > 2) {
          var pointB = parseFloat(intPartFormat).toFixed(2);
          var pointC = pointB.toString().split('.');
          pointAfter = pointC[1].toString();
        }
      }
      // 判断小数点前是否为三位.大于或者等于三位要加千分符.反之只需要补零
      if (pointBefore.length >= 3) {
        if (pointAfter.length === 2) {
          intPartFormat = pointBefore.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
          nowValue = intPartFormat + '.' + pointAfter;
        } else if (pointAfter.length === 1) {
          intPartFormat = pointBefore.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
          nowValue = intPartFormat + '.' + pointAfter + '0';
        } else if (pointAfter === '') {
          intPartFormat = pointBefore.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
          nowValue = intPartFormat + '.' + '00';
        }
      } else {
        if (pointAfter.length === 2) {
          nowValue = pointBefore + '.' + pointAfter;
        } else if (pointAfter.length === 1) {
          nowValue = pointBefore + '.' + pointAfter + '0';
        } else if (pointAfter === '') {
          nowValue = pointBefore + '.' + '00';
        }
      }
    }
    value = nowValue;
    return value;
  }
};

/**
 * 项目中 从数据字典中获取下拉框的数据
 * @param me            上下文
 * @param dtypeArry     当前页面需要获取的数据字典内容数组
 */
util.getSelectData = (me, dtypeArry) => {
  if (localStorage.getItem('sysDict')) {
    const allDicts = JSON.parse(localStorage.getItem('sysDict'));
    dtypeArry.map(function(dtype) {
      if (!allDicts.find(o => o.dtype === dtype)) {
        return;
      }
      let subSysDicts = allDicts.find(o => o.dtype === dtype).sysDicts;
      let resArry = [];
      subSysDicts.map(function(o) {
        resArry.push({
          label: o.dvalue,
          value: o.dkey
        });
      });
      me[dtype + 'Data'] = resArry;
    });
  }
};

// scrollTop animation
util.scrollTop = (el, from = 0, to, duration = 500, endCallback) => {
  if (!window.requestAnimationFrame) {
    window.requestAnimationFrame =
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.msRequestAnimationFrame ||
      function(callback) {
        return window.setTimeout(callback, 1000 / 60);
      };
  }
  const difference = Math.abs(from - to);
  const step = Math.ceil((difference / duration) * 50);

  const scroll = (start, end, step) => {
    if (start === end) {
      endCallback && endCallback();
      return;
    }

    let d = start + step > end ? end : start + step;
    if (start > end) {
      d = start - step < end ? end : start - step;
    }

    if (el === window) {
      window.scrollTo(d, d);
    } else {
      el.scrollTop = d;
    }
    window.requestAnimationFrame(() => scroll(d, end, step));
  };
  scroll(from, to, step);
};

/**
 * @description 绑定事件 on(element, event, handler)
 */
util.on = (function() {
  if (document.addEventListener) {
    return function(element, event, handler) {
      if (element && event && handler) {
        element.addEventListener(event, handler, false);
      }
    };
  } else {
    return function(element, event, handler) {
      if (element && event && handler) {
        element.attachEvent('on' + event, handler);
      }
    };
  }
})();

/**
 * @description 解绑事件 off(element, event, handler)
 */
util.off = (function() {
  if (document.removeEventListener) {
    return function(element, event, handler) {
      if (element && event) {
        element.removeEventListener(event, handler, false);
      }
    };
  } else {
    return function(element, event, handler) {
      if (element && event) {
        element.detachEvent('on' + event, handler);
      }
    };
  }
})();

util.fileDownLoad = ({ id, name, size, url, content }) => {
  if (!id) return;

  if (size) {
    var curLoading = content.$notify({
      title: content.$t('common.prompt'),
      dangerouslyUseHTMLString: true,
      message: '<div>' + content.$t('accessory.downloading') + '</div>',
      duration: 0
    });
  }
  content
    .$myHttp({
      method: 'post',
      url: content.permissionPrefix + url,
      data: {
        entity: {
          id: id
        }
      },
      onDownloadProgress: progressEvent => {
        // Do whatever you want with the native progress event
        if (size) {
          let curLoad = progressEvent.loaded;

          let okLoad = ((curLoad / size) * 100).toFixed(2);

          curLoading.message = '<div>' + content.$t('accessory.downloading') + okLoad + '%</div>';
          if (parseInt(okLoad) > 99) {
            curLoading.message = '<div>' + content.$t('accessory.downloadCompletes') + '</div>';
            curLoading.close();
          }
        }
      },
      responseType: 'arraybuffer'
    })
    .then(res => {
      let headers = res.headers;
      let blob = new Blob([res.data], {
        type: headers['content-type']
      });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      name ? (link.download = name) : (link.download = headers.filename);
      link.click();
      // firefox 里面触发
      let browser = navigator.userAgent.toLowerCase();
      if (browser.indexOf('firefox') > -1) {
        let event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        link.dispatchEvent(event);
      }
    });
};


/**
 * 关闭当前页签方法
 * @param name 当前页签路由name
 * @param vm   传入 this
 */
util.closeCurrentTag = (name, vm) => {
  let pageOpenedList = vm.$store.state.app.pageOpenedList;
  let lastPageObj = pageOpenedList[0];
  if (name) {
    let len = pageOpenedList.length;
    for (let i = 1; i < len; i++) {
      if (pageOpenedList[i].name === name) {
        if (i < len - 1) {
          lastPageObj = pageOpenedList[i + 1];
        } else {
          lastPageObj = pageOpenedList[i - 1];
        }
        break;
      }
    }
  }
  vm.$store.commit('removeTag', name);
  vm.$store.commit('closePage', name);
  pageOpenedList = vm.$store.state.app.pageOpenedList;
  localStorage.pageOpenedList = JSON.stringify(pageOpenedList);
  let routerObj = {};
  routerObj.name = lastPageObj.name;
  if (lastPageObj.argu) {
    routerObj.params = lastPageObj.argu;
  }
  if (lastPageObj.query) {
    routerObj.query = lastPageObj.query;
  }
  vm.$router.push(routerObj);
};

util.dateFormat = function(date, format) {
  let ret;
  let opt = {
    'y+': date.getFullYear().toString(), // 年
    'M+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'm+': date.getMinutes().toString(), // 分
    's+': date.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp('(' + k + ')').exec(format);
    if (ret) {
      format = format.replace(ret[1], ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'));
    }
  }
  return format;
};
/**
 * 根据工单类型，公司，部门获取工单和流程配置中配置的mappingId
 *
 **/
util.getProcessMappingId = _this => {
  var userInfo = JSON.parse(localStorage.getItem('userInfo'));
  _this
    .$myHttp({
      method: 'post',
      url: _this.prefix + '/microarch/activiti/mapping/findBywoCodeAndOrgDept' + window.suffix,
      data: {
        entity: {
          woCode: _this.woCode,
          orgId: userInfo.companyId,
          deptId: userInfo.deptId
        }
      }
    })
    .then(res => {
      if (res.data.ext.code === '2000') {
        _this.mappingId = res.data.ext.mappingId;
      } else {
        this.$message({
          message: res.data.ext.message,
          type: 'error',
          duration: 1500,
          onClose: () => {}
        });
      }
    });
};

/**
 * 设置当前页签名称
 * @param name 页签名称
 * @param vm 当前页面this
 */
util.setCurrentTagName = (name, vm) => {
  vm.$store.state.app.pageOpenedList.find(item => {
    if (item.name === vm.$route.name) {
      console.log('修改成功', name);
      item.title = name;
    }
  });
};

/**
 * 写缓存,缓存编辑页,刷新停留在编辑页
 * @param tabName (必填) 每个菜单定义一个编码,不能重复.在 mounted 事件钩子里会用到
 * @param eidteFlag toEdite 方法里调用时必填 edite
 * @param activedId toEdite 方法里调用时必填
 */
export function activedTabStor(tabName, eidteFlag, activedId) {
  var actived = JSON.parse(sessionStorage.getItem('activedTab') || '{}');
  actived.actived_tabsName = tabName;
  actived.actived_id = activedId || '';
  actived.eidte_flag = eidteFlag || '';
  sessionStorage.setItem('activedTab', JSON.stringify(actived));
}

/**
 * 四舍五入方法
 * @param val 值
 * @param v2 保留位数 两位100,三位1000,4为10000
 * @returns {*}
 */
util.format45 = (val, v2) => {
  if (isNaN(val) || val === undefined || val === null) {
    return null;
  }
  return Math.round(val * v2) / v2;
};

/**
 * 四舍五入方法 保留2位
 * @param val 值
 * @returns {*}
 */
util.formatKeepTwo = val => {
  return util.format45(val, 100);
};

/**
 * 四舍五入方法 保留3位
 * @param val 值
 * @returns {*}
 */
util.formatKeepThree = val => {
  return util.format45(val, 1000);
};

/**
 * 四舍五入方法 保留4位
 * @param val 值
 * @returns {*}
 */
util.formatKeepFour = val => {
  return util.format45(val, 10000);
};

/**
 * 0 校验
 * @param msg
 * @returns {function(*, *, *): void}
 */
util.zeroValueValidator = msg => {
  return (rule, value, callback) => {
    if (value <= 0) {
      callback(new Error(msg));
    } else {
      callback();
    }
  };
};

/**
 * 手机号校验
 * @param msg
 * @returns {function(*, *=, *): void}
 */
util.phoneNumberValidator = msg => {
  var reg = /^(((13[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[3-8]{1})|(18[0-9]{1})|(19[0-9]{1})|(14[5-7]{1}))+\d{8})$/;
  return (rule, value, callback) => {
    if (!reg.test(value)) {
      callback(new Error(msg));
    } else {
      callback();
    }
  };
};

/**
 * 获取流程主题Code
 * @param orderCode 单据Code
 * @param processInstanceName 单据流程名称
 */
util.getWorkflowCode = (orderCode, processInstanceName) => {
  // if (processInstanceName.indexOf(orderCode) !== -1) {
  //     return processInstanceName;
  // }
  if (processInstanceName.indexOf('-') === -1) {
    return orderCode + '-' + processInstanceName;
  }
  let array = processInstanceName.split('-');
  let newProcessInstanceName = array[array.length - 1];
  return orderCode + '-' + newProcessInstanceName;
};

/**
 * code转数据字典
 * @param v
 * @param dictName
 * @returns {string}
 */
util.setDict = (v, dictName) => {
  let sysDict = JSON.parse(localStorage.getItem('sysDict'));
  let type = [];
  for (var items in sysDict) {
    if (sysDict[items].dtype === dictName) {
      type = sysDict[items].sysDicts;
    }
  }
  var res = '';
  if (type) {
    type.forEach(function(item) {
      if (item.dkey === v) {
        res = item.dvalue;
      }
    });
  }
  return res;
};

util.convertCurrency = money => {
  // 汉字的数字
  var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');
  // 基本单位
  var cnIntRadice = new Array('', '拾', '佰', '仟');
  // 对应整数部分扩展单位
  var cnIntUnits = new Array('', '万', '亿', '兆');
  // 对应小数部分单位
  var cnDecUnits = new Array('角', '分', '毫', '厘');
  // 整数金额时后面跟的字符
  var cnInteger = '整';
  // 整型完以后的单位
  var cnIntLast = '元';
  // 最大处理的数字
  var maxNum = 999999999999999.9999;
  // 金额整数部分
  var integerNum;
  // 金额小数部分
  var decimalNum;
  // 输出的中文金额字符串
  var chineseStr = '';
  // 分离金额后用的数组，预定义
  var parts;
  if (money === '') {
    return '';
  }
  money = parseFloat(money);
  if (money >= maxNum) {
    // 超出最大处理数字
    return '';
  }
  if (money === 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger;
    return chineseStr;
  }
  // 转换为字符串
  money = money.toString();
  if (money.indexOf('.') === -1) {
    integerNum = money;
    decimalNum = '';
  } else {
    parts = money.split('.');
    integerNum = parts[0];
    decimalNum = parts[1].substr(0, 4);
  }
  // 获取整型部分转换
  if (parseInt(integerNum, 10) > 0) {
    var zeroCount = 0;
    var IntLen = integerNum.length;
    for (var i = 0; i < IntLen; i++) {
      var n = integerNum.substr(i, 1);
      var p = IntLen - i - 1;
      var q = p / 4;
      var m = p % 4;
      if (n === '0') {
        zeroCount++;
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0];
        }
        // 归零
        zeroCount = 0;
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
      }
      if (m === 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q];
      }
    }
    chineseStr += cnIntLast;
  }
  // 小数部分
  if (decimalNum !== '') {
    var decLen = decimalNum.length;
    for (let i = 0; i < decLen; i++) {
      let n = decimalNum.substr(i, 1);
      if (n !== '0') {
        chineseStr += cnNums[Number(n)] + cnDecUnits[i];
      }
    }
  }
  if (chineseStr === '') {
    chineseStr += cnNums[0] + cnIntLast + cnInteger;
  } else if (decimalNum === '') {
    chineseStr += cnInteger;
  }
  return chineseStr;
};

// util.formatAmount = (number, decimals = 2, decPoint = '.', thousandsSep = ',') => {
//   let numberTemp = String(number).replace(/[^0-9+-Ee.]/g, '');
//   numberTemp = !isFinite(+numberTemp) ? 0 : +numberTemp;
//   const decimalsTemp = !isFinite(+decimals) ? 0 : Math.abs(decimals);
//   const thousandsSepTemp = typeof thousandsSep === 'undefined' ? ',' : thousandsSep;
//   const decPointTemp = typeof decPoint === 'undefined' ? '.' : decPoint;
//   let s = '';
//   const toFixedFix = function(n, prec) {
//     const k = Math.pow(10, prec);
//     return Math.round(n * k) / k;
//   };
//   s = String(toFixedFix(numberTemp, decimalsTemp)).split('.');
//   const re = /(-?\d+)(\d{3})/;
//   while (re.test(s[0])) {
//     s[0] = s[0].replace(re, '$1' + thousandsSepTemp + '$2');
//   }
//   if ((s[1] || '').length < decimalsTemp) {
//     s[1] = s[1] || '';
//     s[1] += new Array(decimalsTemp - s[1].length + 1).join('0');
//   }
//   return s.join(decPointTemp);
// };

/**
 * 金额格式化
 * @param number 金额
 * @param decimals 小数位
 * @param isCovering 是否补位
 * @param decPoint 小数点
 * @param thousandsSep 千分位
 * @returns {string}
 */
util.formatAmount = (number, decimals = 2, isCovering = false, decPoint = '.', thousandsSep = ',') => {
  let numberTemp = String(number).replace(/[^0-9+-Ee.]/g, '');
  numberTemp = !isFinite(+numberTemp) ? 0 : +numberTemp;
  const decimalsTemp = !isFinite(+decimals) ? 0 : Math.abs(decimals);
  const thousandsSepTemp = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep;
  const decPointTemp = (typeof decPoint === 'undefined') ? '.' : decPoint;
  let s = '';
  const toFixedFix = function (n, prec) {
    const k = Math.pow(10, prec);
    return Math.round(n * k) / k;
  };
  s = String(toFixedFix(numberTemp, decimalsTemp)).split('.');
  const re = /(-?\d+)(\d{3})/;
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, '$1' + thousandsSepTemp + '$2');
  }
  if (isCovering) {
    if ((s[1] || '').length < decimalsTemp) {
      s[1] = s[1] || '';
      s[1] += new Array(decimalsTemp - s[1].length + 1).join('0');
    }
  }
  return s.join(decPointTemp);
};

util.getCurrencyName = (orgId) => {
  let localCurrencySet = JSON.parse(localStorage.getItem('companyBaseCurrencySet'));
  if (!orgId) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    orgId = userInfo.companyId;
  }
  let currencySet = localCurrencySet.filter(it => it.orgId === orgId);
  if (currencySet && currencySet.length !== 0) {
    return currencySet[0].baseCurrencyName;
  }
  return '';
};

/**
 * 根据国际化配置, 从 { zh: '中文', en: '英文' } 中获取对应的值, 如果当前语言取值不存在, 则返回另一个语言的值
 * @param {{ zh: string, en: string }} mapping 国际化取值对象
 * @param {string} lang 当前语言
 * @returns {string} 国际化配置中对应的值
 */
util.getInternationalValue = (mapping, lang = i18n.locale.split('_')[0]) => {
  return mapping[lang] || Object.values(mapping).filter(item => item)[0];
}

export default util;
