<template>
  <div :style="{width:forwardBtn && reverseBtn ? '66.6666%':'auto',flex: forwardBtn && reverseBtn ? 'auto' : '1'}">
    <div v-if="taskType === 'waitfor' || submitVisable"
         class='approve-btn'
         style='display:flex;'>
      <van-button v-if='forwardBtn'
                  size='small'
                  type='primary'
                  style='background:rgb(67, 120, 190);border-color:rgb(67, 120, 190);'
                  @click='transactProcess(false)'>{{ forwardBtnName }}
      </van-button>
      <van-button v-if='reverseBtn'
                  size='small'
                  type='primary'
                  style='background:rgba(234, 105, 105, 1);border-color:rgba(234, 105, 105, 1);'
                  @click='transactProcess(true)'>{{
          reverseBtnName
        }}
      </van-button>
      <van-button v-if='seekVisable'
                  size='small'
                  type='primary'
                  style='background:rgb(67, 120, 190);border-color:rgb(67, 120, 190);'
                  @click='seekOpinion()'>{{
          consultBtnName
        }}
      </van-button>
    </div>

    <div v-if="taskType === 'transated'"
         class='approve-btn'>
      <van-button v-show='transatedInfo.callBack'
                  size='small'
                  type='primary'
                  @click='callBack()'>取回
      </van-button>
    </div>

    <van-popup position='bottom' v-model='showPanel' :close-on-click-overlay='false' :close-on-popstate='true'>
      <my-approval-panel
        :detail='detail'
        :waitfor-info='waitforInfo'
        :transact-data='transactData'
        :is-back='isBack'
        :taskType='taskType'
        :variables='businessInfo.variables'
        @dismissApprovalPanel='showPanel = false'
        @processCallback='processCallback'
        @checkBeforeProcess='checkBeforeProcess'
        @beforeSubmitCallBack='beforeSubmitCallBack'
        @closeStartCallback='closeStartCallback'
      ></my-approval-panel>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from 'vant';
import myApprovalPanel from '@/views/business/components/myApprovalPanel';

export default {
  name: 'myApproval',
  components: {
    myApprovalPanel
  },
  props: {
    businessInfo: {}, //业务详情数据
    customSubmitBtnName: {
      // 自定义审批按钮名称
      type: Array,
      default: () => []
    },
    processName: {
      type: String,
      default: ''
    },
    applyRefs: {
      type: Object,
      default: function() {
        return {};
      }
    },
    submitVisable: {
      type: Boolean,
      default: false
    },
    woCodeValue: {
      type: String,
      default: ''
    },
    saveUrl: {
      type: String,
      default: ''
    },
    taskId: {
      type: String,
      default: ''
    },
    detailPageName: '', // 返回或取消是 如符合此路由 name 属性则会调用取消审批流程接口后再 next()
    applyChangeNo: {   //变更页面原始数据
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showPanel: false,
      radio: '1',
      opinion: '',
      process: false,
      approval: false,
      isBack: false,
      forwardBtn: true,
      reverseBtn: true,
      seekEnable: false, // 征询
      seekVisable: false, // 征询按钮显示
      commonOpinions: [],
      transactData: {},
      transatedInfo: {},
      waitforInfo: {},
      forwardBtnName: this.customSubmitBtnName[0] || '提交审批',
      reverseBtnName: this.customSubmitBtnName[1] || '撤销审批',
      consultBtnName: '征询',
      detail: {
        id: ''
      }, // 单据信息 List页面跳转单据详情页面的数据
      taskType: '', // 类型：waitfor  transated
      woCode: '',
      userName: (this.$route.query.row && JSON.parse(this.$route.query.row).pid ? JSON.parse(this.$route.query.row).candidate || JSON.parse(this.$route.query.row).assignee : sessionStorage.getItem('userName'))
    };
  },
  mounted() {
  },
  watch: {
    woCodeValue: {
      handler(newName, oldName) {
        if (newName) {
          this.woCode = newName;
        }
      },
      // 代表在wacth里声明了firstName这个方法之后立即先去执行handler方法
      immediate: true
    },
    taskId: function(curVal, oldVal) {
      this.detail.id = curVal;
    },
    reverseBtn: function(curVal, oldVal) {
      if (curVal && this.forwardBtn) {
        this.$emit('changeReverseBtn');
      }
    }
  },
  created() {
    this.detail.id = this.taskId;
    this.taskType = this.$route.query.type;

    if (this.submitVisable) {
      this.reverseBtn = false;
    }
    // console.log(this.taskType)
    this.initPage();
  },
  methods: {
    initPage() {
      if (this.taskType === 'waitfor') {
        this.getWaitforTask();
      } else if (this.taskType === 'transated') {
        this.getTransated();
      }
    },
    getWaitforTask() {
      this.$myHttp({
        method: 'GET',
        url: '/microarch/activiti/task/waitfor/' + this.detail.id
      }).then(res => {
        this.waitforInfo = res.data;
        //当前节点为发起人，提交按钮显示'提交审批'
        if (this.waitforInfo.activityId === 'apply') {
          this.forwardBtnName = '提交审批';
          this.reverseBtn = false;
        }

        //当前节点为会签节点时，隐藏反向审批按钮
        if (this.waitforInfo.isCountersign) {
          this.reverseBtn = false;
        }
        this.detail = res.data;
        this.getBtnConfig();
      });
    },
    getBtnConfig() {
      this.$myHttp({
        method: 'GET',
        url: '/microarch/activiti/btnConfig2?taskId=' + this.detail.id + '&taskType=waitfor'
      }).then(res => {
        if (res.data.repelEnable) {
          this.reverseBtn = true;
        }
        if (res.data.seekEnable) {
          this.seekEnable = res.data.seekEnable;
        }
        let isFirstNode = this.detail.activityId === 'apply';
        if (this.detail.id !== undefined && this.detail.id !== '') {
          this.seekVisable = true && this.seekEnable;
        }
        if(isFirstNode){
          this.seekVisable = false;
        }
        if (this.detail.isSeeking) {
          this.forwardBtn = false;
          this.reverseBtn = false;
          this.seekVisable = true && this.seekEnable;
        }
      });
    },
    getTransated() {
      this.$myHttp({
        method: 'GET',
        url: '/microarch/activiti/task/transated/' + this.detail.id
      }).then(res => {
        this.transatedInfo = res.data;
        this.detail = res.data;
      });
    },

    //取回
    callBack() {
      this.$dialog
        .confirm({
          title: '确认取回？'
        })
        .then(() => {
          // on confirm
          let params = {
            optionType: 'callBackProcess',
            optionText: '取回',
            taskId: this.transatedInfo.taskId,
            processInstanceId: this.detail.pid,
            nextId: this.detail.activityId,
            priority: '50',
            withdrawId: this.transatedInfo.id,
            userName: this.userName,
            candidateUserIds: {
              [this.detail.activityId]: [this.userName]
            }
          };
          this.$myHttp({
            method: 'POST',
            url: '/microarch/activiti/callBack',
            data: params
          }).then(res => {
            // this.syncProcess('callBackProcess');
            this.$router.replace({
              name: 'workFlow/workFlowList',
              type: 'transated'
            });
            this.$emit('process-callback', params.processInstanceId, 'callBackProcess');
          });
        });
    },
    transactProcess(back) {
      this.detail.optType = '';
      if (this.applyRefs.form && !back) {
        this.applyRefs.form
          .validate()
          .then(res => {
            this.transactProcessHandle(back);
          })
          .catch(err => {
          });
      } else {
        this.transactProcessHandle(back);
      }
    },
    async transactProcessHandle(back) {
      let option = back ? 'return' : 'submit';
      this.isBack = back;
      if (option == 'return' && this.reverseBtnName == '撤销审批') {
        this.repelProcess();
      } else if (this.taskType) {
        if (option == 'submit' && !this.detail.id) {
          this.getWorkflow(option);
        } else {
          this.awaittransactProcess(option);
        }
      } else {
        this.$emit('saveApplyHandle', false, () => {
          if (option == 'submit' && !this.detail.id) {
            this.getWorkflow(option);
          } else {
            this.awaittransactProcess(option);
          }
        });
      }
    },
    /**
     * 撤销审批
     * 删除流程信息 业务表单从流程中剥离
     * */
    repelProcess() {
      var param = {};
      param.optionType = 'repelProcess';
      param.optionText = 'revocationProcess';
      this.$dialog.confirm({
        title: '撤销审批',
        message: '确认撤销流程？'
      })
        .then(() => {
          // on confirm
          this.$myHttp({
            method: 'GET',
            url: '/microarch/activiti/undo/' + this.detail.pid,
            params: param
          }).then(res => {
            if (res.data.success == undefined || res.data.success) {
              Toast.success('撤销成功');
              this.EhrUtils.updateFormState(this, `/hrtbm/${this.saveUrl}/updateFormState`, this.detail.businessKey, this.TbmCons.FormState.TOVOID).then(res => {
                if (res.data.entity) {
                  this.goWorkFlowPage();
                }
              });
            } else {
              Toast.fail(res.data.msg);
            }

          }).catch(_ => {
            Toast.fail('单据异常，不可操作，请重新刷新单据或者联系管理员。');
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    awaittransactProcess(option) {
      let activitiData = JSON.parse(sessionStorage.getItem('activitiData'));
      let deptAndPost = parseInt(sessionStorage.getItem('deptAndPost'));
      let variables = {};
      if (activitiData) {
        variables = {
          activitiData: {
            ...activitiData,
            deptAndPost
          }
        };
      }
      this.$myHttp({
        method: 'post',
        url: '/microarch/activiti/transact',
        data: {
          businessKey: this.detail.businessKey,
          taskId: this.detail.id,
          processInstanceId: this.detail.pid,
          variables,
          userName: this.userName,
          woCode: this.woCode,
          direction: this.isBack?'in':'out'
        }
      }).then(res => {
        if (res.data.msg) {
          Toast.fail({
            duration: 20000,
            message: res.data.msg
          });
        } else {
          // this.$emit('checkBeforeProcess', option);
          this.transactData = res.data;
          this.detail.updateUrl = this.saveUrl;
          // this.$router.push({
          //   name: 'approvalPanel',
          //   query: {
          //     transactData: this.transactData,
          //     waitforInfo: this.waitforInfo,
          //     detail: this.detail,
          //     isBack: this.isBack,
          //     detailPageName: this.detailPageName,
          //     applyChangeNo: this.applyChangeNo
          //   },
          //   params: {
          //     taskType: this.taskType,
          //     submitVisable: this.submitVisable,
          //     updateStateUrl: this.updateStateUrl,
          //     businessKey: this.businessInfo.id
          //   }
          // });
          this.showPanel = true;
        }
      });
    },
    async getWorkflow(option) {
      const mappingId = await this.$myHttp({
        url: '/microarch/activiti/mapping/findBywoCodeAndOrgDept',
        method: 'post',
        data: {
          entity: {
            woCode: this.woCode,
            orgId: this.businessInfo.pkOrg,
            deptId: this.businessInfo.pkDept
          }
        }
      }).then(res => {
        if (res.status == 200) {
          return res.data.ext.mappingId;
        }
      });

      let activitiData = JSON.parse(sessionStorage.getItem('activitiData'));
      let deptAndPost = parseInt(sessionStorage.getItem('deptAndPost'));
      let variables = {};
      if (activitiData) {
        variables = {
          activitiData: {
            ...activitiData,
            deptAndPost
          }
        };
      }
      this.$myHttp({
        url: '/microarch/activiti/startWorkflow',
        method: 'post',
        data: {
          businessKey: this.businessInfo.id,
          processInstanceName: this.processName,
          userName: this.userName,
          variables,
          mappingId: mappingId
        }
      }).then(res => {
        if (res.status == 200) {
          this.detail = {
            id: res.data.taskId,
            name: res.data.taskName,
            pid: res.data.processInstanceId,
            businessKey: this.businessInfo.id,
            taskType: 'waitfor',
            priority: res.data.priority,
            endProcessType: 'add',
            updateUrl: this.saveUrl
          };
          this.waitforInfo = {
            name: res.data.taskName,
            isCountersign: res.data.countersign,
            priority: res.data.priority,
            businessInfo: this.processName
          };
          this.awaittransactProcess(option);
        }
      });
    },
    // syncProcess (option) {
    //   this.$myHttp({
    //     url: '/hrpm/comm/syncProcess',
    //     method: 'post',
    //     params: {
    //       formId: this.detail.businessKey,
    //       pid: this.detail.pid
    //     }
    //   }).then(res => {
    //     this.goWorkFlowPage();
    //   });
    // },
    goWorkFlowPage() {
      this.loading = Toast.loading({
        message: '同步中,请等待...',
        forbidClick: true,
        duration: 0
      });
      setTimeout(() => {
        Toast.clear();
        this.$router.replace({
          name: 'workFlow/workFlowList',
          query: {
            type: 'transated'
          }
        });
      }, 5000);
    },
    processCallback(piid, optionType, taskType) {
      this.$emit('processCallback', piid, optionType, taskType);
    },
    checkBeforeProcess(type) {
      this.$emit('checkBeforeProcess', type);
    },
    beforeSubmitCallBack(params) {
      this.$emit('beforeSubmitCallBack', params);
    },
    closeStartCallback(pid) {
      this.$emit('closeStartCallback', pid);
    },
    /**
     * 征询页面展示
     * */
    seekOpinion () {
      var _this = this;
      var param = {};
      // _this.form.seekOpinion = '';
      // _this.nextId = '';
      // _this.seeker = '';
      // _this.seekerName = '';
      param.businessKey = _this.detail.businessKey;
      param.processInstanceId = _this.detail.pid;
      param.processInstanceName = _this.processName;
      param.taskId = _this.detail.id;
      param.taskName = _this.detail.name;
      param.priority = _this.detail.priority;
      // param.goId = _this.goId;
      param.goId = '';
      param.nextId = '';
      param.opinion = '';
      // param.userName = _this.userName; // Cookies.get('user');
      // param.seekIndex = _this.seekIndex;
      param.userName = ''; // Cookies.get('user');
      param.seekIndex = '';
      this.$myHttp({
        method: 'POST',
        url: '/microarch/activiti/seekOpinion',
        data: param
      }).then(res => {
        if (res.data.success == undefined || res.data.success) {
          this.detail.optType = 'seekOpinion';
          this.transactData = res.data;
          this.detail.updateUrl = this.saveUrl;
          this.detail.optionList = res.data.activityList;
          this.detail.seekIndex = res.data.seekIndex;
          if (res.data.backActivityList.length > 0) {
            this.detail.optionList = this.detail.optionList.concat(res.data.backActivityList);
          }
          _this.showSeekUser = false;
          _this.dialogSeekVisible = true;
          if (res.data.commonOpinions.length > 0) {
            var resArry = [];
            for (var d = 0, len = res.data.commonOpinions.length; d < len; d++) {
              resArry.push({
                value: res.data.commonOpinions[d].opinion
              });
            }
            _this.commonOpinions = resArry;
          }

          this.showPanel = true;
        } else {
          this.$message({
            message: res.data.msg,
            type: 'warning'
          });
        }
      });
    },
  },
  filters: {
    priorityFil(val) {
      const colorMap = {
        50: ['green', '一般'],
        100: ['orange', '紧急'],
        150: ['red', '特急']
      };

      let color = colorMap[val] || ['green', '一般'];

      return color[1];
    }
  }
};
</script>


