<template>
  <div>
    <van-nav-bar left-arrow
                 title='审批操作'
                 fixed
                 @click-left='back'/>

    <div class='approve-container'>
      <van-form ref='form'>
        <van-cell title='当前节点' title-style='width:20%'
                  :value='waitforInfo.name'/>

        <van-cell title='下一节点'
                  v-if='parallel'
                  :value='nextNodeName'/>

        <van-cell title='下一节点'
                  v-else-if="detail.optType && detail.optType === 'seekOpinion'"
                  is-link
                  :value='nextNodeData.name'
                  @click='seekOptionNextNodeDialog = true'/>

        <van-cell title='下一节点'
                  v-else
                  is-link
                  :value='nextNodeData.name'
                  @click='nextNodeDialog = true'/>

        <van-cell title='处理人'
                  v-if='!parallel'
                  v-show='dealingValue'
                  is-link
                  :value='dealingValue.fullName'
                  @click='dealingDialog = true'/>

        <van-cell v-show='!nextNodeEnd'
                  :title="item.name + '  处理人'"
                  v-for='(item, index) in nextNodeData.item'
                  :key='index'
                  v-else
                  :is-link='!result.find(o => o.id === item.id)'
                  :value="
            result
              .filter(o => o.id === item.id)
              .map(o => o.fullName)
              .join('、')
          "
                  @click='
            choseDealingPeople(
              item,
              result.find(o => o.id === item.id)
            )
          '/>

        <van-cell title='处理人'
                  v-if='!parallel'
                  v-show='detail.optType === "seekOpinion" && showSeekDeal'
                  is-link
                  :value='seekerName'
                  @click='selectUserDialog = true'/>

        <van-cell title='会签意向'
                  v-show='waitforInfo.isCountersign'
                  is-link
                  :value='agree | agreeFil'
                  @click='agreeDialog = true'/>

        <van-cell title='紧急程度'
                  :value='waitforInfo.priority | priorityFil'/>

        <van-field v-model='opinion'
                   rows='2'
                   autosize
                   label='审批意见'
                   placeholder='请输入'
                   type='textarea'
                   maxlength='200'
                   show-word-limit
                   :required='isBack'
                   :rules="[{ required: isBack, message: '请填写审批意见' }]"/>
        <div style='color: #0f6ecd;line-height:40px;padding-left:106px;font-size:13px;background:#fff;'
             @click='saveAfterAddOpioion'>设置为常用意见
        </div>
      </van-form>
      <div style='color: #0f6ecd;line-height:40px;padding-left:16px;font-size:14px;'>常用意见</div>
      <div style='display:flex;flex-wrap:wrap;padding:0px 15px 30px;'
           class='vanTags'>
        <van-tag plain
                 type='primary'
                 size='large'
                 v-for='(item,index) in commonOpinions'
                 :key='index'
                 @click='opinion=item.opinion'
                 style='margin-right: 10px;margin-bottom:10px;'>
          {{ item.opinion }}
        </van-tag>
      </div>

    </div>

    <div style='width:100%;position:fixed;bottom:0;height:50px;display:flex;'>
      <van-button size='small'
                  type='info'
                  style='flex:1;height:50px;font-size:16px;'
                  @click='handleSubmit()'>确定
      </van-button>
      <van-button size='small'
                  plain
                  type='primary'
                  style='flex:1;height:50px;font-size:16px;'
                  @click='back'>取消
      </van-button>
    </div>

    <van-dialog v-model='nextNodeDialog'
                title='选择下一节点'
                show-cancel-button
                confirm-button-color='#000000'
                cancel-button-color='#000000'
                @confirm='choseNextNode'>
      <van-radio-group v-model='nextNodeData'>
        <van-cell-group>
          <van-cell :title='item.name'
                    clickable
                    v-for='(item, index) in nextNodeSelectData'
                    :key='index'
                    @click='nextNodeData = item'>
            <van-radio slot='right-icon'
                       :name='item'/>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </van-dialog>

    <van-dialog v-model='seekOptionNextNodeDialog'
                title='选择下一节点'
                show-cancel-button
                confirm-button-color='#000000'
                cancel-button-color='#000000'
                @confirm='choseSeekOptionNextNode'>
      <van-radio-group v-model='nextNodeData'>
        <van-cell-group>
          <van-cell :title='item.name'
                    clickable
                    v-for='(item, index) in optionList'
                    :key='index'
                    @click='nextNodeData = item'>
            <van-radio slot='right-icon'
                       :name='item'/>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </van-dialog>

    <van-dialog v-model='dealingDialog'
                title='选择处理人'
                confirm-button-color='#000000'
                cancel-button-color='#000000'
                show-cancel-button>
      <van-radio-group v-model='dealingValue'>
        <van-cell-group>
          <van-cell :title='item.fullName'
                    clickable
                    v-for='(item, index) in dealingSelectData'
                    :key='index'
                    @click='dealingValue = item'>
            <van-radio slot='right-icon'
                       :name='item'/>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </van-dialog>

    <van-dialog v-model='selectUserDialog'
                confirm-button-color='#000000'
                cancel-button-color='#000000'
                :show-confirm-button="ourtableData.length>0"
                :show-cancel-button="!ourtableData.length"
                title='搜索处理人'>
      <van-search
        v-model="userSearchVal"
        placeholder="请输入用户名关键词"
        @search="onUserSearch"
      />
      <van-radio-group v-model='seekerName'>
        <van-cell-group>
          <van-cell :title='item.cname'
                    clickable
                    v-for='(item, index) in ourtableData'
                    :key='index'
                    @click='handleUserSelect(item)'>
            <van-radio slot='right-icon'
                       :name='item.cname'/>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </van-dialog>

    <van-dialog v-model='dealingParallelDialog'
                title='选择处理人'
                show-cancel-button>
      <van-checkbox-group v-model='result'>
        <van-cell-group>
          <van-cell :title='item.fullName'
                    clickable
                    v-for='(item, index) in dealingSelectData'
                    :key='index'
                    @click='toggle(index)'>
            <van-checkbox slot='right-icon'
                          ref='checkboxes'
                          :name='item'/>
          </van-cell>
        </van-cell-group>
      </van-checkbox-group>
    </van-dialog>

    <van-dialog v-model='agreeDialog'
                confirm-button-color='#000000'
                cancel-button-color='#000000'
                title='选择会签意见'
                show-cancel-button>
      <van-radio-group v-model='agree'>
        <van-cell-group>
          <van-cell title='同意'
                    clickable
                    @click="agree = '1'">
            <van-radio slot='right-icon'
                       name='1'/>
          </van-cell>
          <van-cell title='不同意'
                    clickable
                    @click="agree = '0'">
            <van-radio slot='right-icon'
                       name='0'/>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </van-dialog>
  </div>
</template>

<script>
  import {Toast} from 'vant';

  export default {
    name: 'myApprovalPanel',
    props: {
      transactData: {
        type: Object
      },
      waitforInfo: {
        type: Object
      },
      detail: {
        type: Object
      },
      isBack: Boolean,
      variables: {
        type: Object
      },
      taskType: ''
    },
    data() {
      return {
        showSeekDeal: false,
        selectUserDialog: false,
        userSearchVal: '',
        ourtableData: [],
        optionList: [],
        seeker: '',
        seekerName: '',
        seekOptionNextNodeDialog: false,
        detailPageName: '',
        agree: '1',
        result: [],
        nextNodeName: '',
        opinion: '',
        nextNodeDialog: false,
        nextNodeData: {},
        dealingDialog: false,
        dealingParallelDialog: false,
        agreeDialog: false,
        dealingValue: '',
        radio: '',
        nextNodeSelectData: [],
        dealingSelectData: [],
        parallel: false,
        nextNodeEnd: false,
        toName: '',
        commonOpinions: [],
        userName: (this.$route.query.row && this.$route.query.row.pid ? this.$route.query.row.candidate || this.$route.query.row.assignee : sessionStorage.getItem('userName'))
      };
    },
    watch: {
      transactData() {
        this.mountedInit();
      }
    },
    created() {
      console.log('transactData');
      console.log(this.transactData);
      console.log('detail');
      console.log(this.waitforInfo);
      this.detail = this.detail;
      this.detailPageName = this.detailPageName || '';

      console.log(this, 'aaaaaaaaaaaaaaaaaaa');
    },
    mounted() {
      this.mountedInit();
    },
    methods: {
      mountedInit() {
        this.transactData = this.transactData;
        this.waitforInfo = this.waitforInfo;
        console.log(this.waitforInfo.name, 6666666666666666666666666666666);
        if (this.isBack) {
          this.nextNodeSelectData = this.transactData.backActivityList;
          this.dealingSelectData = this.nextNodeSelectData[0].item;
          this.nextNodeData = this.nextNodeSelectData[0];
          this.dealingValue = this.dealingSelectData[0];
        } else {
          if (this.transactData.activityList.length > 0) {
            //非并行
            this.nextNodeSelectData = this.transactData.activityList;
            if (this.nextNodeSelectData[0].item) {
              this.dealingSelectData = this.nextNodeSelectData[0].item.selectItems;
              console.log('this.dealingSelectData', this.dealingSelectData);

              this.dealingValue = this.dealingSelectData[0];
            }
            console.log('nextNodeData1', this.nextNodeSelectData[0]);
            this.nextNodeData = this.nextNodeSelectData[0];
          } else {
            //并行网关
            this.parallel = true;
            this.nextNodeData = this.transactData.parallelActivityList[0];
            this.nextNodeName = this.nextNodeData.item.map(o => o.name).join('、');
            console.log('this.nextNodeData', this.nextNodeData.item);
            if (this.nextNodeData.item[0].id == 'end') {
              this.nextNodeEnd = true;
              return null;
            } else {
              this.nextNodeEnd = false;
            }
            this.nextNodeData.item.forEach(items => {
              //并行某一节点的处理人为一个时，默认选中
              console.log('selectItems', items);
              if (items.item.selectItems.length === 1) {
                this.result.push({
                  fullName: items.item.selectItems[0].fullName,
                  id: items.id,
                  name: items.name,
                  userName: items.item.selectItems[0].userName,
                  notLink: true
                });
              }
            });
          }
        }

        // 征询下一节点选项
        if (this.detail.optType && this.detail.optType === 'seekOpinion'){
          this.showSeekDeal = true;
          this.optionList = [];
          if (this.transactData.activityList && this.transactData.activityList.length > 0){
            this.optionList = this.optionList.concat(this.transactData.activityList);
          }
          if (this.transactData.backActivityList && this.transactData.backActivityList.length > 0){
            this.optionList = this.optionList.concat(this.transactData.backActivityList);
          }
        }

        if (this.transactData) {
          this.commonOpinions = this.transactData.commonOpinions;
        }
      },
      saveAfterAddOpioion() {
        if (this.opinion == '') {
          this.$notify({type: 'danger', message: '未填写审批意见'});
          return null;
        }
        var param = {};
        param.opinion = this.opinion;
        param.userName = this.userName;
        this.$myHttp({
          method: 'POST',
          url: '/microarch/activiti/saveCommonOpinion',
          data: param
        }).then(res => {
          Toast.success('设置成功');
          this.commonOpinions.unshift({'opinion': this.opinion});
        });
      },
      toggle(index) {
        this.$refs.checkboxes[index].toggle();
      },
      choseDealingPeople(item, data) {
        if (data && data.notLink) return;
        this.dealingParallelDialog = true;
        item.item.selectItems.forEach(items => {
          items.id = item.id;
          items.name = item.name;
        });
        this.dealingSelectData = item.item.selectItems;
      },
      choseNextNode() {
        this.nextNodeDialog = true;
        if (!this.nextNodeData.item.selectItems) {
          this.dealingSelectData = this.nextNodeData.item;
        } else {
          this.dealingSelectData = this.nextNodeData.item.selectItems;
        }
        this.dealingValue = this.dealingSelectData[0];
      },
      choseSeekOptionNextNode() {
        this.seekOptionNextNodeDialog = true;

        if (this.nextNodeData.isBack) {
          this.showSeekDeal = false;
          this.dealingSelectData = this.nextNodeData.item;
          this.dealingValue = this.dealingSelectData[0];
          this.seeker = this.dealingSelectData[0].userName;
        } else {
          this.showSeekDeal = true;
          this.dealingSelectData = [];
          this.dealingValue = "";
          this.seeker = '';
          this.seekerName = '';
        }
      },
      back() {
        // this.$router.go(-1);
        this.$emit('dismissApprovalPanel');
      },
      handleSubmit() {
        this.$refs.form
          .validate()
          .then(res => {
            if (this.detail.optType && this.detail.optType == 'seekOpinion'){
              this.confirmSeek();
            }else{
              this.confirmProcess();
            }
          })
          .catch(err => {
          });
      },
      confirmProcess() {
        // 强制执行签收动作
        const params = {
          businessKey: this.detail.businessKey,
          processInstanceId: this.detail.pid,
          processInstanceName: this.waitforInfo.businessInfo,
          taskId: this.detail.id,
          taskName: this.detail.name,
          priority: this.detail.priority,
          goId: '',
          opinion: this.opinion,
          email: '1',
          userName: this.userName,
          isStartTask: false,
          variables: {},
          nextId: this.nextNodeData.id,
          optionType: this.isBack ? 'back' : 'submit',
          optionText: this.isBack ? '退回' : '提交审批',
          candidateUserIds: {},
          autoNodes: [],
          encyclicUserIds: [''],
          businessUrl: ''
        };
        if (this.parallel) {
          this.nextNodeData.item.forEach(items => {
            params.candidateUserIds[items.id] = this.result.filter(o => o.id === items.id).map(o => o.userName);
          });
        } else {
          params.candidateUserIds = {
            [this.nextNodeData.id]: [this.dealingValue.userName]
          };
        }

        //会签节点，提交同意/不同意参数
        if (this.waitforInfo.isCountersign) {
          params.agree = this.agree === '1';
          params.opinion = (this.agree === '1' ? '同意。' : '不同意。') + this.opinion;
          params.countersign = true;
        }
        // 结束节点 无审批人
        if (params.nextId === 'end' || this.nextNodeEnd) params.candidateUserIds = {};

        if (
          (params.nextId !== 'end' || !this.nextNodeEnd) &&
          params.candidateUserIds &&
          Object.values(params.candidateUserIds).find(item => item.length === 0)
        ) {
          this.$toast.fail('请选择处理人！');
          return;
        }
        if (this.hasMethodByName(this, 'beforeSubmitCallBack')) {
          this.$emit('beforeSubmitCallBack', params);
          this.back();
        }
      },

      /**
       * 递归校验父组件回调函数是否存在
       * */
      hasMethodByName(context, componentName) {
        let parent = context.$parent;
        while (parent && !parent[componentName]) {
          parent = parent.$parent;
        }

        return !!parent;
      },

      async processCallback(optionType, taskType) {
        if (optionType == this.TbmCons.OptionType.RETURN) {// 不同意/驳回
          this.EhrUtils.updateFormState(this, `/hrtbm/${this.detail.updateUrl}/updateFormState`, this.detail.businessKey, this.TbmCons.FormState.INAUDIT).then(res => {
            if (res.data.entity) {
              this.goWorkFlowPage();
            }
          });
        }
        if (optionType == this.TbmCons.OptionType.REPEL_PROCESS) {// 撤销审批
          this.EhrUtils.updateFormState(this, `/hrtbm/${this.detail.updateUrl}/updateFormState`, this.detail.businessKey, this.TbmCons.FormState.TOVOID).then(res => {
            if (res.data.entity) {
              this.goWorkFlowPage();
            }
          });
        }
        if (optionType == this.TbmCons.OptionType.FORCE_END_PROCESS) {// 强制结束
          this.EhrUtils.updateFormState(this, `/hrtbm/${this.detail.updateUrl}/updateFormState`, this.detail.businessKey, this.TbmCons.FormState.TOVOID).then(res => {
            if (res.data.entity) {
              this.goWorkFlowPage();
            }
          });
        }
        if (optionType == this.TbmCons.OptionType.END_PROCESS) { // 完成审批
          if (this.applyChangeNo) {
            this.EhrUtils.updateFormState(this, `/hrtbm/${this.detail.updateUrl}/updateFormState`, this.applyChangeNo, this.TbmCons.FormState.TOVOID);
          }
          this.EhrUtils.updateFormState(this, `/hrtbm/${this.detail.updateUrl}/updateFormState`, this.detail.businessKey, this.TbmCons.FormState.AUDITED).then(res => {
            if (res.data.entity) {
              this.goWorkFlowPage();
            }
          });
        }
        // if (taskType == this.TbmCons.TaskType.TRANSLATED) {// 已办
        //   this.$router.replace({
        //     name: 'workFlow/workFlowList',
        //     type: 'transated'
        //   });
        // } else if (taskType == this.TbmCons.TaskType.WAIT_FOR) {// 待办
        //   this.$router.replace({
        //     name: 'workFlow/workFlowList',
        //     type: 'waitfor'
        //   });
        // } else if (taskType == this.TbmCons.TaskType.TO_READ) {// 待阅
        //   this.$router.replace({
        //     name: 'workFlow/workFlowList',
        //     type: 'read'
        //   });
        // } else if (taskType == this.TbmCons.TaskType.READ) {// 已阅
        //   this.$router.replace({
        //     name: 'workFlow/workFlowList',
        //     type: 'readed'
        //   });
        // }
        if (optionType == this.TbmCons.OptionType.SUBMIT_PROCESS) { // 提交审批
          this.EhrUtils.updateFormState(this, `/hrtbm/${this.detail.updateUrl}/updateFormState`, this.detail.businessKey, this.TbmCons.FormState.INAUDIT).then(res => {
            if (res.data.entity) {
              this.goWorkFlowPage();
            }
          });
        }
      },

      goWorkFlowPage() {
        this.loading = Toast.loading({
          message: '同步中,请等待...',
          forbidClick: true,
          duration: 0
        });
        setTimeout(() => {
          Toast.clear();
          this.$router.replace({
            name: 'Home'
          });
        }, 5000);
      },


      /**
       * 征询确认
       * */
      confirmSeek () {
        // this.seeker = '956273801855885312'; // seeker是userid
        var _this = this;
        var param = {};
        var users = {};
        param.businessKey = _this.detail.businessKey;
        param.processInstanceId = _this.detail.pid;
        param.processInstanceName = _this.waitforInfo.businessInfo;
        param.taskId = _this.detail.id;
        param.taskName = _this.detail.name;
        param.priority = _this.detail.priority;
        param.goId = '';
        param.nextId = _this.nextNodeData.id;
        param.opinion= _this.opinion;
        param.userName = _this.userName; // Cookies.get('user');
        param.seekIndex = _this.detail.seekIndex;
        if (param.nextId == '') {
          this.$message({
            message: this.$t('errorMsg.noDirection'),
            type: 'warning'
          });
          return null;
        }
        if (_this.seeker instanceof Array) {
          param.candidateUserIds = _this.seeker;
          if (_this.seeker.length == 0) {
            Toast('处理人为空，请选择处理人！');
            return null;
          }
        } else {
          if (!_this.seeker) {
            Toast('处理人为空，请选择处理人！');
            return null;
          } else {
            param.candidateUserIds = [_this.seeker];
          }
        }

        // var content = _this.form.seekOpinion;
        var content = _this.opinion;
        content = content.replace(/\ +/g, ''); // 去掉空格
        content = content.replace(/[ ]/g, ''); // 去掉空格
        content = content.replace(/[\r\n]/g, ''); // 去掉回车换行
        var s = content.length;
        if (s == 0) {
          Toast('审批意见不能为空');
          return null;
        }
        users[param.nextId] = param.candidateUserIds;
        param.candidateUserIds = users;
        if (!_this.isSeeking) {
          param.optionType = 'seekingStart';
          param.optionText = '征询开始';
        } else {
          param.optionType = 'seeking';
          param.optionText = '征询';
        }

        this.$myHttp({
          method: 'POST',
          url: '/microarch/activiti/seekSubmit',
          data: param
        }).then(res => {
          if (res.data.success == undefined || res.data.success) {
            _this.dialogSeekVisible = false;
            // 判断父组件是否存在回调方法 存在即调用父组件回调方法
            if (_this.hasMethodByName(this, 'processCallback')) {
              _this.$emit('processCallback', _this.processInstanceId, 'seekingProcess', _this.taskType);
            }
          } else {
            Toast(res.data.msg);
          }
        }).catch(_ => {
          Toast('单据异常，不可操作，请重新刷新单据或者联系管理员。');
        });
      },

      /**
       * 弹窗搜索用户
       */
      onUserSearch(param){
        if (param){
          let condition = [{
            'field': 'cname',
            'value': param
          }];
          this.queryUserList(1,20,condition)
        }
      },

      queryUserList (currentPage, currentSize, conditions) {
        var params = {
          entity:{},
          'where': conditions,
          'pageNum': 0,
          'pageSize': 10,
          'orderBy': 'id asc'
        };
        // userManage.queryList(params).then(res=>{
        params.where = conditions || [];
        params.pageNum = currentPage - 1;
        params.pageSize = currentSize;
        params.entity = {
          lang:this.$i18n.locale
        }

        this.$myHttp({
          method: 'post',
          url: '/microarch/sys/sysuser/list',
          data: params
        }).then(res => {
          console.log('res---------userList---', res);
          if (res.data && res.data.rows) {
            this.ourtableData = [];
            this.total = parseInt(res.data.total);
            for (var i = 0; i < res.data.rows.length; i++) {
              var rowContent = {};
              rowContent.id = res.data.rows[i].id ? res.data.rows[i].id : '';
              rowContent.userName = res.data.rows[i].userName ? res.data.rows[i].userName : '';
              rowContent.loginName = res.data.rows[i].loginName ? res.data.rows[i].loginName : '';
              rowContent.cname = res.data.rows[i].cname ? res.data.rows[i].cname : '';
              rowContent.company = res.data.rows[i].company && res.data.rows[i].company ? res.data.rows[i].company : '';
              rowContent.mobile = res.data.rows[i].mobile ? res.data.rows[i].mobile : '';
              rowContent.email = res.data.rows[i].email ? res.data.rows[i].email : '';
              rowContent.status = res.data.rows[i].status == 'Y' ? '启用' : res.data.rows[i].status == 'N' ? '停用' : '';
              rowContent.role = res.data.rows[i].roleNames ? res.data.rows[i].roleNames : '';
              this.ourtableData.push(rowContent);
            }
          }
        });
      },

      handleUserSelect(item){
        this.seekerName = item.cname;
        this.seeker = item.userName;
      }
    },

    beforeRouteLeave(to, from, next) {
      if (this.detailPageName && to.name == this.detailPageName && !this.$route.params.taskType) {
        this.$myHttp({
          method: 'GET',
          url: '/microarch/activiti/instance/delete/' + this.detail.pid,
          data: {}
        }).then(res => {
          if (res.status == 200) {
            next();
          }
        });
      } else {
        sessionStorage.removeItem('formData');
        next();
      }
    },

    filters: {
      priorityFil(val) {
        const colorMap = {
          50: ['green', '一般'],
          100: ['orange', '紧急'],
          150: ['red', '特急']
        };

        let color = colorMap[val] || ['green', '一般'];

        return color[1];
      },
      agreeFil(val) {
        return val === '1' ? '同意' : '不同意';
      }
    }
  };
</script>

<style scoped lang='less'>
  .approve-container {
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .van-radio {
    margin-left: 5px;
  }
</style>
<style>
  .vanTags > span {
    max-width: 95%;
    white-space: nowrap;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
  }
</style>

